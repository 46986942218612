<template>

  <el-main>
    <el-row>
      <el-col :span="24">
        <h3>各平台单个任务耗时表</h3>
      </el-col>
    </el-row>
    <el-table
        :data="tableData"
        style="width: 100%">
      <el-table-column
          prop="platform_name"
          label="平台"
      >
      </el-table-column>
      <el-table-column
          prop="type_cn"
          label="任务类型"
      >
      </el-table-column>
      <el-table-column
          prop="time_cost_cn"
          label="单个任务耗时"
      >
      </el-table-column>
    </el-table>
  </el-main>
</template>

<script>
export default {
  name: "Manual",
  created() {
    let me = this;
    me.axios.get('/api/platform/list').then(res => {
      res.forEach(res => {
        me.map.platform['id_' + res.id] = res.name;
      })
      me.axios.get('/api/platform/dataQueue').then(res => {
        me.rawData = res;
      })
    })
  },
  data() {
    return {
      map: {
        platform: {},
        type: {
          'id_1': '关键词',
          'id_2': '评论'
        },
        source:{
          'id_1': 'APP',
          'id_2': 'WEB'
        }
      },
      rawData: []
    }

  },
  computed: {
    tableData() {
      let me = this;
      if (!me.rawData) {
        return [];
      }
      return me.rawData.map(val => {
        val['platform_name'] = me.map.platform['id_' + val['platform_id']];
        val['type_cn'] = me.map.type['id_' + val['task_type']];
        val['time_cost_cn'] = me.formatTime(val['time_cost']);
        return val;
      })
    }
  }
}
</script>

<style scoped>

</style>